import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pi pi-info-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AutoComplete, {
      ref: "contactAutoComplete",
      forceSelection: "",
      modelValue: _ctx.query,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
      placeholder: "Search Contacts",
      suggestions: _ctx.contactsList,
      field: _ctx.getFullname,
      disabled: _ctx.disabled,
      onComplete: _ctx.handleComplete,
      onItemSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleItemSelect($event))),
      dropdown: _ctx.showDropDown,
      delay: 1500,
      onKeydown: _withKeys(_ctx.handleEnter, ["enter"])
    }, null, 8, ["modelValue", "suggestions", "field", "disabled", "onComplete", "dropdown", "onKeydown"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showContactDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showContactDialog) = $event)),
      header: "Contact Search",
      modal: true,
      style: {"width":"90%","max-width":"1200px"}
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Select",
          onClick: _ctx.handleLookupSelect
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_LookupDataTable, {
          lazyLoadPagination: true,
          "manual-load": true,
          ref: "contactLookupTable",
          lookup: _ctx.getLookup,
          params: _ctx.getLookupParameters,
          class: "p-datatable-sm w-12 text-sm bordered-datatable",
          onOnLoad: _ctx.handleOnLoad,
          onRowClick: _ctx.handleRowClick,
          onDoubleClick: _ctx.handleDoubleClick,
          columnBodyStyle: { 'align-content': 'flex-start' }
        }, {
          primary: _withCtx(({ data }) => [
            (data.assoc_cust?.includes(_ctx.custId))
              ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_1, null, 512)), [
                  [_directive_tooltip, `This contact is associated with customer #${_ctx.custId}`]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["lookup", "params", "onOnLoad", "onRowClick", "onDoubleClick"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}